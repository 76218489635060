<template>
  <div class="works">
    <LeftMenu current="updateApplyList"></LeftMenu>
    <div class="main" :class="{ updateApplyList: $route.meta.active }">
      <div class="manage">
        <div class="content">
          <div class="backBox">
            <el-breadcrumb separator="/">
            <el-breadcrumb-item to="/applyList">发行管理</el-breadcrumb-item>
            <el-breadcrumb-item>修改申请</el-breadcrumb-item>
          </el-breadcrumb>
          <el-link type="primary" @click="$router.back()">返回></el-link>
          </div>
          <el-divider></el-divider>
          <div style="margin-top: 30px;" class="formBox">
            <div class="datePickerBox">
              <el-date-picker
                v-model="dateValue"
                type="daterange"
                @change="dateChange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                :default-time="['00:00:00', '23:59:59']"
              >
              </el-date-picker>
            </div>

            <div class="selectBox">
              <el-select
                @change="userChange"
                value-key="id"
                v-model="params.search_operator"
                placeholder="请选择"
              >
                <el-option :value="0" :key="0" label="操作人"><div style="padding:0 20px">操作人</div></el-option>
                <el-option
                  v-for="item in userList"
                  :key="item.id"
                  :label="item.nickName"
                  :value="item.id"
                >
                <div style="padding:0 20px">{{item.nickName}}</div>
                </el-option>
              </el-select>
            </div>

            <div class="inputBox">
              <el-input
                prefix-icon="el-icon-search"
                v-model="params.name"
                placeholder="请输入专辑或歌曲名称关键字"
              >
              </el-input>
            </div>

            <el-button
              @click="resetFn"
              style="margin-left: 20px"
              class="reset btn"
              >重置</el-button
            >
            <el-button @click="submitFn" class="submit btn" type="primary"
              >筛选</el-button
            >

          </div>
          <update-apply-list :userList="userList" @cancelFn="cancelFn" @checkFn="checkFn" @updateFn="updateFn" :albumList="albumArr"></update-apply-list>
          <div class="footer-pagination">
          <el-pagination
            :current-page="paging.cur_page"
            :page-size="paging.size"
            @current-change="currentChange"
            background
            layout="prev, pager, next, slot"
            hide-on-single-page
            :total="paging.total">
          <ul class="alone">
            <li class="totalPage">
              共 {{paging.max_page}} 页
            </li>
            <li>到第
              <el-input v-model.number="inputVal"></el-input>
            </li>
            <li>
              <el-button  @click="butfn()">确定</el-button>
            </li>
          </ul>
        </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <div class="dialogDiv">
      <el-dialog
        :close-on-click-modal="false"
        title="操作确认"
        :visible.sync="popShow"
        height="360px"
        width="480px"
        center
      >
        <div class="popBody">{{popData.title}}</div>
        <span slot="footer" class="dialog-footer">
          <el-button type="info" @click="popShow = false">取 消</el-button>
          <el-button :loading='submitLoading' type="primary" @click="albumCancelFn" class="determine">确 定</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import UpdateApplyList from "./components/applyList/updateApplyList";
import LeftMenu from "../../components/LeftMenu";
import { getUserList ,getUpdateList,albumCancel} from "@/api/issue/";
const SIZE = 10;
export default {
  components: { UpdateApplyList, LeftMenu },
  data() {
    return {
      popShow:false,
      submitLoading:false,
      popData:{
        album_id:'',
        album_name:'',
        title:''
      },
      params: {
        end_time: "",
        size: SIZE,
        start_time: "",
        search_operator: '',
        page:1,
        name: "",
      },
      inputVal:1,
      paging:{
        total:0,
        size:SIZE
      },
      userList: [],
      dateValue: [],
      albumArr: [],
    };
  },
  created() {
    this.getUserListFn();
    this.getListFn();
  },
  methods: {
    albumCancelFn(){
      this.submitLoading = true
      albumCancel({album_id:this.popData.album_id},'album','cancel').then(res=>{
        if(res.code == 0){
          this.popShow = false
          this.submitLoading = false
          this.albumArr.forEach(item=>{
            if(item.album_id == this.popData.album_id){
              item.status = -1
            }
          })
          this.$message({
          message: '撤销成功',
          type: 'success'
        });
        }
      }).catch(()=>{
        this.submitLoading = false
      })

    },
    cancelFn(row){
      let {album_id,album_name = ''} = row
      let title =`请确认是否撤回专辑 “${album_name}”的发行`
      this.popData = {
        album_id,
        title,
        album_name
      }
      this.popShow = true
    },
    checkFn(row){
      this.$router.push(`/query/${row.album_id}/${row.ver}`)
    },
    updateFn(row){
      this.$router.push(`/issue/${row.album_id}/${row.ver}`)
    },
    dateChange(date) {
      if (date) {
        this.params.start_time = `${date[0]}  00:00:00`;
        this.params.end_time = `${date[1]} 23:59:59`;
      } else {
        this.params.start_time = "";
        this.params.end_time = "";
      }
    },

    submitFn() {
      this.getListFn();
    },

    currentChange(val){
      this.params.page = val
      this.getListFn();
    },

    butfn(){
      this.params.page = this.inputVal
      this.getListFn();
    },

    resetFn() {
      this.params = {
        end_time: "",
        size: SIZE,
        start_time: "",
        search_operator: '',
        page:1,
        name: "",
      };
      this.dateValue = [];
      this.getListFn();
    },
    //操作人列表
    getUserListFn() {
      getUserList().then((res) => {
        if (res.code == 0) {
          this.userList = res.data;
        }
      });
    },

    //选择操作人
    userChange(data) {
      this.params.search_operator = data;
    },

    getListFn() {
      getUpdateList(this.params,'album','editList').then(res=>{
        if(res.code === 0){
        let { data,total,cur_page,max_page,per_page } = res.data
        this.paging.total = total
        this.paging.cur_page = cur_page
        this.paging.max_page = max_page
        this.paging.per_page = per_page
        this.albumArr = data
       }
      })
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../styles/works";

.updateApplyList {
  padding: 0;
  background-color: #f7f7f7;
}
* {
  margin: 0;
  padding: 0;
  list-style: none;
}
::v-deep .el-input__inner {
        border: 1px solid #e5e5e5;
        border-radius: 3px;
        padding: 0 10px;
      }
.manage {

  .content {
    box-sizing: border-box;
    background-color: #ffffff;
    width: 100%;
    min-height: 700px;
    padding: 30px 40px;
    .backBox{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .formBox {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .btn {
        width: 96px;
        height: 40px;
        border-radius: 2px;
        font-size: 14px;
      }


      .datePickerBox {
        height: 40px;
        width: 386px;
        margin-right: 26px;
        ::v-deep .el-input__inner {
          height: 40px;
          background: #ffffff;
          border: 1px solid #e5e5e5;
          border-radius: 3px;
          padding: 0 10px;
          width: 384px;
        }
      }
      .selectBox {
        margin-right: 26px;
        ::v-deep .el-select {
          width: 220px;
        }

      }
      .inputBox {
        margin-right: 30px;
        ::v-deep .el-input__inner {
          width: 230px;
          padding: 0 30px;
        }
      }
    }
  }
}
.footer-pagination {
      margin: 39px 0 39px 0;
      display: flex;
      justify-content: center;

      .alone {
        display: inline-flex;
        height: 30px;
        align-items: center;
        font-size: 12px;
        font-family: PingFang SC-Regular;
        font-weight: 400;
        color: #555555;

        .totalPage {
          width: 40px;
          margin: 0 17px 0 6px;
        }

        ::v-deep .el-input {
          .el-input__inner {
            width: 30px;
            height: 30px;
            border: 1px solid #e1e0e0;
            border-radius: 2px;
          }
        }
        .el-button{
          margin-left:6px;
          width: 48px;
          height: 30px;
          background: #ffffff;
          border: 1px solid #e1e0e0;
          border-radius: 2px;
        }
      }

      .alone > li {
        display: inline-block;
      }
    }
    .dialogDiv{
    .el-button{
      width: 160px;
      height: 40px;
      border-radius: 3px;
    }
    .determine{
      margin-left: 25px;

    }
    ::v-deep .el-dialog{
      border-radius: 10px;
    }
    .popBody{
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      color: #333333;
      font-size: 14px;
    }
   ::v-deep .el-dialog__footer{
      padding:0 0  40px 0;
    }
  }
</style>
