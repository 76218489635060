<template>
  <el-table
      :data="tableData"
      class="table"
      ref="table"
      :header-cell-style="{
        background:'#f7f7f7',
        fontSize: '14px',
        fontFamily:'PingFang SC-Regular',
        fontWeight: 400,
        color: '#333333',
      }"
      style="width: 100%">
    <el-table-column label="序号" width="70"  >
      <template slot-scope="scope">{{scope.$index+1}}</template>
    </el-table-column>

    <el-table-column label="专辑" width="220"  prop="name" v-slot="{row}" >
      <div style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;" :title="row.album_name">
        {{ row.album_name }}
      </div>
    </el-table-column>

    <el-table-column label="歌曲"  width="220" prop="name" v-slot="{row}" >
      <div class="songNames" >
        <div :title="row.album_name" style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;" v-for="(item,index) in row.song_names" :key="index">{{item}}</div>
      </div>
    </el-table-column>

    <el-table-column label="修改版本" width="100"  prop="name" v-slot="{row}" >
      <div style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;" :title="row.album_name">
        {{ row.ver }}
      </div>
    </el-table-column>


    <el-table-column label="提交时间"   v-slot="{row}">
      <div>{{ moment(row.submit_at).format('YYYY-MM-DD HH:mm')}}</div>
    </el-table-column>

    <el-table-column label="操作人" :formatter="operator" />

    <el-table-column  label="状态" width="140" v-slot="{row}">
      {{issueType(row)}}
     <template >
       
     </template>
    </el-table-column>

    <el-table-column
        
        v-slot="{row}"
        width="120"
        label="操作"
        >
      <div class="btnsBox">
        <div v-if="showRevoke(row)" @click="cancelFn(row)" class="pointer">撤销</div>
        <div @click="checkFn(row)" class="pointer">查看</div>
        <div v-if="showUpdate(row)" @click="updateFn(row)" class="pointer">修改</div>
      </div>
    </el-table-column>
   
  </el-table>
</template>

<script>
import {delAlbum} from "@/api/song"
import moment from "moment"
export default {
  props:{
    albumList: Array,
    userList:Array
  },
  name: "albumList",
  data(){
    return {
      tableData: [],
      op:[],
      moment
    }
  },

 
  methods:{
    //撤销按钮
    showRevoke(row){
      let {status} = row
      let isShow = false
      if(status == 1 || status == 2 || status == -3 || status == -4){
        isShow = true
      }
      return isShow
    },

    showUpdate(row){
      let {status} = row
      let isShow = false
      if(status == -1 || status == -2){
        isShow = true
      }
      return isShow
    },
    coverFn(row){
      let {cover} = row
      let url = ''
      if(cover){
        url = cover.location
      }
      return url
    },
   
    deleteAlbum(id){
      delAlbum({}, {albumId: id}).then(() => {
        this.$parent.albumListFn()
        this.$refs['popover-' + id].showPopper = false
      })
    },
    operator(val){
      let name = '--'
      this.userList.forEach(item=>{
        if(item.id == val.operator){
          name = item.nickName
        }
      })
      return name
    },

    cancelFn(row){
      this.$emit('cancelFn',row)
    },
    checkFn(row){
      this.$emit('checkFn',row)
    },
    updateFn(row){
      this.$emit('updateFn',row)
    },
  // 发行状态筛选
    issueType(val){
      let {status} = val
      if(status == 0){
        return '草稿'
      }else if(status == 1) {
        return '待曲库审核'
      }else if(status == 2) {
        return '待发行审核'
      }else if(status == 3) {
        return '发行中'
      }else if(status == 4) {
        return '待上架'
      }else if(status == 5) {
        return '已上架'
      }else if(status == -1) {
        return '已撤销'
      }else if(status == -2) {
        return '曲库审核不通过'
      }else if(status == -3) {
        return '发行审核不通过'
      }else if(status == -4) {
        return '发行失败'
      }else if(status == -5) {
        return '发行成功'
      }else{
        return ''
      }
    }
  },
  mounted() {
    this.tableData = this.albumList
  },
  watch:{
    albumList(val){
    this.tableData = val
    }
  }
}
</script>

<style scoped lang="scss">
.hideExpend{
  display: none;
}
</style>
<style>

.el-table__expand-column .cell {
  display: none;
}
.image-slot{
  width: 56px;
  height: 56px;
  background: #f5f7fa;
  font-size: 40px;
  color: #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btnsBox{
  
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.pointer{
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FF477E;
  font-size: 14px;
  margin-right: 20px;
}
.doBox{
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.items{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .items span{
    color: #999;
    font-size: 12px;
    padding-bottom: 11px;
  }
  .items i{
    color: #666;
    font-size: 12px;
    font-style: normal;
  }
  .dd{
    display: flex;
  }
  .iconBox{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .icon{
    font-size: 16px;
    cursor: pointer;
    margin-left: 6px;
  }
  .songNames{
    display: flex;
    flex-direction: column;
  }
</style>
